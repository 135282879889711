import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop, CircularProgress } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import IFSCDetails from "../BeneficiaryList/IFSCDetails";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const [dataFromChe, setDataFromChe] = useState(null);
  const [ifscD, setIfscD] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);
  const [perm, setPerm] = useState([]);
  const MobNo = watch("mobile");
  const EmailNo = watch("email");
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/3");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  const companyType = watch("company_type");
  const [{ data: paymentMethodList }] = useQueries({
    queries: [
      {
        queryKey: ["methodlist"],
        queryFn: () =>
          api.get("admin/get-payment-method-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.name);
      setValue("dealer_type", existingData?.dealer_type);
      setValue("dob", existingData?.dob);
      setValue("mobile", existingData?.mobile);
      setValue("alternative_number", existingData?.alternative_number);
      setValue("email", existingData?.email);
      setValue("pan_number", existingData?.pan_number);
      setValue("beneficiary_name", existingData?.beneficiary_name);
      setValue("account_number", existingData?.account_number);
      setValue("ifsc_code", existingData?.ifsc_code);
      setValue("micr_code", existingData?.micr_code);
      setValue("landmark", existingData?.landmark);
      setValue("city", existingData?.city);
      setValue("pincode", existingData?.pincode);
      setValue("address_line_1", existingData?.address_line_1);
      setValue("address_line_2", existingData?.address_line_2);
      setValue("company_type", existingData?.company_type);
      setValue("beneficiary_name", existingData?.beneficiary_name);
      setValue("account_number", existingData?.account_number);
      setValue("ifsc_code", existingData?.ifsc_code);
      setValue("micr_code", existingData?.micr_code);
      setValue("onboarding_fees", existingData?.onboarding_fees);
      setValue("payment_method", existingData?.payment_method);
      setValue("longitude", existingData?.longitude);
      setValue("latitude", existingData?.latitude);
      // setValue("dealer_loi", existingData?.dealer_loi);
    }
  }, [existingData, setValue, paymentMethodList]);
  console.log("existingData", existingData);
  const addifsc = () => {
    setIfscD((val) => !val);
  };
  const handleifscRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setIfscD((val) => !val);
  };
  useEffect(() => {
    if (dataFromChe) {
      setValue("account_number", dataFromChe.account_number);

      setValue("ifsc_code", dataFromChe.ifsc_code);
      setValue("micr_code", dataFromChe.micr_code);
    }
  }, [dataFromChe, setValue]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    const fileInput = document.querySelector('input[name="security_cheque_1"]');
    const file = fileInput?.files[0];

    // Check if file is uploaded and OCR verification data is missing
    if (file && !dataFromChe) {
      showToast(
        "Please verify the cheque image before submitting the form.",
        "error"
      );
      return;
    }

    // Append non-file fields
    formData.append("name", data.name);
    formData.append("dob", data.dob);
    formData.append("mobile", data.mobile);
    formData.append("alternative_number", data.alternative_number);
    formData.append("email", data.email);
    formData.append("pan_number", data.pan_number);
    formData.append("dealer_type", data.dealer_type);
    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("pincode", data.pincode);
    formData.append("address_line_1", data.address_line_1);
    formData.append("address_line_2", data.address_line_2);
    formData.append("account_number", data.account_number);
    formData.append("beneficiary_name", data.beneficiary_name);
    formData.append("ifsc_code", data.ifsc_code);
    formData.append("micr_code", data.micr_code);
    formData.append("onboarding_fees", data.onboarding_fees);
    formData.append("payment_method", data.payment_method);
    formData.append("longitude", data.longitude);
    formData.append("latitude", data.latitude);

    formData.append("company_type", data.company_type);
    if (defaultValue && defaultValue.id) {
      formData.append("id", defaultValue.id);
    }
    // Append file fields
    const fileFields = [
      "bank_statement",
      "trade_certificate",
      "gst_certificate",
      "security_cheque_1",
      "security_cheque_2",
      "security_cheque_3",
      "security_cheque_4",
      "payment_proof_img",
      "rent_or_lease_agreement",
      "dealer_finayo_agreement",

      "dealer_image_1",

      "lat_long_image",
      "showroom_img_1",

      "itr_file",
      "pan",

      "balance_sheet",

      "utility_bill",
      "dealer_loi",
      "partnership_deed",

      "moa",
      "aoa",
      "certificate_of_incorporation",
    ];

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}update-dealer`
        : `${baseURL}store-dealer`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/dealers-list");
    } catch (error) {
      const { message, errors } = error.response.data;

      setErr(message);
      showToast(message, "error");
    }
  };
  const handleSQChange = async (event) => {
    const file = event.target.files[0];
    if (!MobNo || !EmailNo) {
      showToast("Mobile number and email are required", "error");
      event.target.value = null;
      return;
    }
    if (file) {
      const formData = new FormData();

      formData.append("cheque_image", file);
      formData.append("user_type", "DEALER");
      formData.append("email", EmailNo);
      formData.append("mobile", MobNo);
      setLoading(true);
      if (defaultValue && defaultValue.id) {
        formData.append("user_id", defaultValue.id);
      }

      try {
        const apiEndpoint = `${baseURL}admin/verify-admin-cheque-ocr`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");

        setDataFromChe(response.data?.data);
      } catch (error) {
        const { message } = error.response.data;
        showToast(message, "error");
      } finally {
        setLoading(false);
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  useEffect(() => {
    if (existingData) {
      const fileFields = [
        "trade_certificate",
        "gst_certificate",
        "security_cheque_1",
        "payment_proof_img",
        "security_cheque_2",
        "security_cheque_3",
        "security_cheque_4",
        "dealer_finayo_agreement",
        // "lat_long_image",

        "dealer_loi",

        "pan",
      ];

      fileFields.forEach((field) => {
        if (!existingData[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, existingData]);

  useEffect(() => {
    if (companyType === "PARTNERSHIP") {
      if (existingData?.partnership_deed) {
        register("partnership_deed");
      } else {
        register("partnership_deed", { required: true });
      }
    } else {
      register("partnership_deed");
    }
  }, [companyType, register, existingData]);

  useEffect(() => {
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.balance_sheet) {
        register("balance_sheet");
      } else {
        register("balance_sheet");
      }
    } else {
      register("balance_sheet");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.moa) {
        register("moa");
      } else {
        register("moa", { required: true });
      }
    } else {
      register("moa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.aoa) {
        register("aoa");
      } else {
        register("aoa", { required: true });
      }
    } else {
      register("aoa");
    }
    if (companyType === "PRIVATE LIMITED") {
      if (existingData?.certificate_of_incorporation) {
        register("certificate_of_incorporation");
      } else {
        register("certificate_of_incorporation", { required: true });
      }
    } else {
      register("certificate_of_incorporation");
    }
  }, [companyType, register, existingData]);

  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading} // Show loader when loading
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
        <div className={styles.totalcheckconnn}>
          {/* <div className={styles.searchformasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Company Type<span className={styles.requ}>*</span>
            </label>
            <select
              style={{ height: "24px" }}
              className={styles.inputstst}
              {...register("company_type", {
                required: true,
              })}
            >
              <option value="">--Select Company Type--</option>
              <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
              <option value="PARTNERSHIP">PARTNERSHIP</option>
              <option value="PRIVATE LIMITED">PRIVATE LIMITED</option>
            </select>
            {errors.company_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Name<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Dealer Name"
              type="text"
              className={styles.inputstst}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Type<span className={styles.requ}>*</span>
            </label>
            <select
              // onChange={handleCompanyTypeChange}
              style={{ height: "24px" }}
              className={styles.inputstst}
              {...register("dealer_type", {
                required: true,
              })}
            >
              <option value="">--Select Dealer Type--</option>
              <option value="B2B">B2B</option>
              <option value="B2C">B2C</option>
            </select>
            {errors.dealer_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Incorporation<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inputstst}
                {...register("dob", { required: true })}
              />
              {errors.dob && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Missing."}
              </span>
            )}
            <span className={styles.error}>{err.mobile ? err.mobile : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternate Mobile Number
            </label>
            <input
              placeholder="Enter Alternate Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("alternative_number", {
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />

            {errors.alternative_number && (
              <span className={styles.error}>
                {errors.alternative_number.message || "This Field is Required."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Email"
              type="email"
              className={styles.inputstst}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>

          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar Number<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Aadhar Number"
              type="text"
              className={styles.inputstst}
              {...register("aadhar_number", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{12}$/,
                  message: "Please Enter Aadhar Number",
                },
              })}
            />
            
            {errors.aadhar_number && (
              <span className={styles.error}>
                {errors.aadhar_number.message || "This Field is Missing."}
              </span>
            )}
            <span className={styles.error}>
              {err.aadhar_number ? err.aadhar_number : ""}
            </span>
           
          </div> */}
        </div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "15px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 1<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 1"
              type="text"
              className={styles.inputstst}
              {...register("address_line_1", { required: true })}
            />
            {errors.address_line_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 2<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 2"
              type="text"
              className={styles.inputstst}
              {...register("address_line_2", { required: true })}
            />
            {errors.address_line_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              City<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter City"
              type="text"
              className={styles.inputstst}
              {...register("city", { required: true })}
            />
            {errors.city && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Pincode<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Pincode"
              type="number"
              className={styles.inputstst}
              {...register("pincode", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please Enter Valid Pincode",
                },
              })}
            />
            {errors.pincode && (
              <span className={styles.error}>
                {errors.pincode.message || "This Field is Missing."}
              </span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Landmark
            </label>
            <input
              placeholder="Enter Landmark"
              type="text"
              className={styles.inputstst}
              {...register("landmark")}
            />
            {errors.landmark && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No.<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter PAN Number"
              type="text"
              className={styles.inputstst}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              {...register("pan_number", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{10}$/,
                  message: "Please Enter Valid PAN Number",
                },
              })}
            />
            {errors.pan_number && (
              <span className={styles.error}>
                {errors.pan_number.message || "This Field is Missing."}
              </span>
            )}
            {/* {errors.pan_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )} */}
            <span className={styles.error}>
              {err.pan_number ? err.pan_number : ""}
            </span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Onboarding Fees<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Onboarding Fee"
              type="number"
              step="0.01"
              className={styles.inputstst}
              {...register("onboarding_fees", {
                required: true,
              })}
            />

            {errors.onboarding_fees && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Payment Method<span className={styles.requ}>*</span>
            </label>
            <select
              style={{ height: "24px" }}
              className={styles.inputstst}
              {...register("payment_method", {
                required: true,
              })}
            >
              <option value="">--Select Payment Method--</option>
              {paymentMethodList?.data?.map((row, i) => (
                <option key={i} value={row.id}>
                  {row.payment_method}
                </option>
              ))}
            </select>
            {errors.payment_method && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Payment Proof Image <span className={styles.requ}>*</span>
              {existingData && existingData?.payment_proof_img && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.payment_proof_img)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              className={styles.uploadbx}
              {...register("payment_proof_img")}
              type="file"
              accept="image/jpeg, image/png,image/jpg"
            />

            {errors.payment_proof_img && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Longitude
            </label>
            <input
              placeholder="Enter Longitude"
              type="number"
              step="0.00000000001"
              className={styles.inputstst}
              {...register("longitude")}
            />

            {errors.longitude && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Latitude
            </label>
            <input
              placeholder="Enter Latitude"
              type="number"
              step="0.00000000001"
              className={styles.inputstst}
              {...register("latitude")}
            />

            {errors.latitude && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Longitude Latitude Image
              {existingData && existingData?.lat_long_image && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.lat_long_image)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              className={styles.uploadbx}
              {...register("lat_long_image")}
              type="file"
            />

            {errors.lat_long_image && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Upload PAN <span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.uploadbx}
              {...register("pan")}
              type="file"
              accept="image/jpeg, image/png,image/jpg"
              // accept="application/pdf"
              // onChange={handleChange("bank_statement")}
              // onChange={(e) => {
              //   fileChangeHandler(e);
              // }}
            />

            {errors.pan && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            {/* {errors.bank_statement && (
                <span className={styles.error}>This Field is Required.</span>
              )} */}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer LOI<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("dealer_loi")}
              accept="application/pdf"
              //    onChange={handleChange("trade_certificate")}
            />
            {/* <input
                type="text"
                className={styles.inputstst}
                {...register("dealer_loi", { required: true })}
              /> */}
            {errors.dealer_loi && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Finayo Agreement<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("dealer_finayo_agreement")}
              accept="application/pdf"
              //    onChange={handleChange("trade_certificate")}
            />
            {/* <input
                type="text"
                className={styles.inputstst}
                {...register("dealer_loi", { required: true })}
              /> */}
            {errors.dealer_finayo_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Upload Aadhar <span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.uploadbx}
              {...register("aadhar")}
              type="file"
             
            />

            {errors.aadhar && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Bank Statement
            </label>
            <input
              className={styles.uploadbx}
              {...register("bank_statement")}
              type="file"
              accept="application/pdf"
              // onChange={handleChange("bank_statement")}
              // onChange={(e) => {
              //   fileChangeHandler(e);
              // }}
            />

            {errors.bank_statement && (
              <span className={styles.error}>
                {errors.bank_statement.message || "This Field is Missing."}
              </span>
            )}
            {/* {errors.bank_statement && (
                <span className={styles.error}>This Field is Required.</span>
              )} */}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 1
              {existingData && existingData?.security_cheque_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_1)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_1")}
              onChange={handleSQChange}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("security_cheque_1")}
            />
            {errors.security_cheque_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {(existingData?.beneficiary_name || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Beneficiary Name<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                {...register("beneficiary_name", { required: true })}
                className={styles.inputstst}
              />
              {errors.beneficiary_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.account_number || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Account Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                {...register("account_number", { required: true })}
                className={styles.inputstst}
                disabled
              />
              {errors.account_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.ifsc_code || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                IFSC Code<span className={styles.requ}>*</span>{" "}
                {dataFromChe?.ifsc_code && (
                  <button
                    onClick={() => handleifscRow(dataFromChe)}
                    type="button"
                    className={styles.bbtnvofde}
                  >
                    IFSC Details
                  </button>
                )}
              </label>
              <input
                {...register("ifsc_code", { required: true })}
                type="text"
                disabled
                className={styles.inputstst}
              />
              {errors.ifsc_code && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {(existingData?.micr_code || dataFromChe) && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MICR Code<span className={styles.requ}>*</span>{" "}
              </label>
              <input
                {...register("micr_code", { required: true })}
                type="text"
                className={styles.inputstst}
                disabled
              />
              {errors.micr_code && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 2
              {existingData && existingData?.security_cheque_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_2)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_2")}
              accept="image/jpeg, image/png,image/jpg"
              // onChange={handleChange("security_cheque_2")}
            />
            {errors.security_cheque_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 3
              {existingData && existingData?.security_cheque_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_3)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_3")}
              accept="image/jpeg, image/png,image/jpg"
              // onChange={handleChange("security_cheque_3")}
            />
            {errors.security_cheque_3 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 4
              {existingData && existingData?.security_cheque_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData?.security_cheque_4)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_4")}
              accept="image/jpeg, image/png,image/jpg"
              //onChange={handleChange("security_cheque_4")}
            />
            {errors.security_cheque_4 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Certificate<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("gst_certificate")}
              // onChange={handleChange("gst_certificate")}
              accept="application/pdf"
              //onChange={handleChange("gst_certificate")}
            />
            {errors.gst_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Rent Agreement
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("rent_or_lease_agreement")}
              accept="application/pdf"
              //  onChange={handleChange("rent_agreement")}
            />
            {errors.rent_or_lease_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Image
              {existingData && existingData?.dealer_image_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.dealer_image_1)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("dealer_image_1")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("dealer_image_1")}
            />
            {errors.dealer_image_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Image 2<span className={styles.requ}>*</span>
              {existingData && existingData?.dealer_image_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.dealer_image_2)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("dealer_image_2")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("dealer_image_2")}
            />
            {errors.dealer_image_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div> */}

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image
              {existingData && existingData?.showroom_img_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_1)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("showroom_img_1")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("showroom_img_1")}
            />
            {errors.showroom_img_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 2<span className={styles.requ}>*</span>
              {existingData && existingData?.showroom_img_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_2)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("showroom_img_2")}
              accept="image/jpeg, image/png,image/jpg"
              //   onChange={handleChange("showroom_img_2")}
            />
            {errors.showroom_img_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 3
              {existingData && existingData?.showroom_img_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_3)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("showroom_img_3")}
              accept="image/jpeg, image/png,image/jpg"
              //    onChange={handleChange("showroom_img_3")}
            />
            {errors.showroom_img_3 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Showroom Image 4
              {existingData && existingData?.showroom_img_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.showroom_img_4)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              accept="image/jpeg, image/png,image/jpg"
              // onChange={handleChange("showroom_img_4")}
              className={styles.uploadbx}
              {...register("showroom_img_4")}
            />
            {errors.showroom_img_4 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div> */}

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ITR File
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("itr_file")}
              // onChange={handleFileChange}
              accept="application/pdf"
              //   onChange={handleChange("itr_file")}
            />
            {errors.itr_file && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Manufacturing License
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("manufacturing_license")}
              // onChange={handleFileChange}
              accept="application/pdf"
              //   onChange={handleChange("itr_file")}
            />
            {errors.manufacturing_license && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ICAT Certificate
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("icat_certificate")}
              // onChange={handleFileChange}
              accept="application/pdf"
              //   onChange={handleChange("itr_file")}
            />
            {errors.icat_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Utility Bill
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("utility_bill")}
              // accept="image/jpeg, image/png,image/jpg"
              // accept="application/pdf"
              // onChange={fileChangeHandler}
              //  onChange={handleChange("balance_sheet")}
            />
            {errors.utility_bill && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Trade Certificate<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("trade_certificate")}
              accept="application/pdf"
              //    onChange={handleChange("trade_certificate")}
            />
            {errors.trade_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>

          {companyType === "PARTNERSHIP" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Partnership Deed<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("partnership_deed")}
                // accept="image/jpeg, image/png,image/jpg"
                // accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.partnership_deed && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}

          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("balance_sheet")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.balance_sheet && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("moa")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.moa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                AOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aoa")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.aoa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                <span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("certificate_of_incorporation")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.certificate_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {/* {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Factory Address Proof
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("factory_address_proof")}
                // accept="image/jpeg, image/png,image/jpg"
                // accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.factory_address_proof && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )} */}
        </div>
        {perm?.includes("create") && (
          <div className={styles.buttonasc} style={{ marginTop: "15px" }}>
            <button type="submit" className={styles.Subbtn}>
              Update
            </button>
          </div>
        )}
      </form>
      {dataFromChe?.ifsc_code && ifscD && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ifscD}
          onClick={addifsc}
        >
          <IFSCDetails addHandle={addifsc} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
    </div>
  );
};

export default DealerForm;
