import React, { useContext, useState } from "react";
import classes from "../AddLenderEx/Modal.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import LoanCom from "./LoanCom";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../Common/api";
import { useQuery } from "@tanstack/react-query";

const EmiCal = () => {
  const { activeSubmenuId } = useContext(AuthContext);
  const [perm, setPerm] = useState([]);
  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName="Loan Calculator" />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              <LoanCom />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmiCal;
