import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import classes from "./Dashboard.module.css";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const chartData = {
    Mon: 30,
    Tue: 60,
    Wed: 10,
    Thur: 30,
    Fri: 60,
    Sat: 10,
    Sun: 10,
  };

  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: "Avg Fuel efficiency",
        data: Object.values(chartData),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        borderColor: ["#E74C3C", "#2E86C1", "#F4D03F", "#16A085", "#8E44AD"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Avg Ignition Time", // Chart title inside the graph
        font: {
          size: 14, // Title font size
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%" }} className={classes.shdbds}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
