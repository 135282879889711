import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaFileDownload, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import RemarkModal from "./RemarkModal";
import api from "../../Common/api";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [type, setType] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };
  // console.log("rowToEdit", rowToEdit);
  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    setType(type);
    // updateVpnMutation.mutate(payload);
    // console.log("id", id);
    setAdd((val) => !val);
  };
  // const HandleDownloadDoc = (file) => {
  //   setRowToEdit(file);
  // };
  // const HandleDownloadDoc = (file) => {
  //   const fileUrl = `https://insta.finayo.tech/${file}`;

  //   // Create an anchor element and set the href and download attributes
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = file;
  //   document.body.appendChild(link);
  //   link.click();

  //   document.body.removeChild(link);
  // };
  // const HandleDownloadDoc = (file) => {
  //   const fileUrl = `https://insta.finayo.tech/${file}`;
  //   console.log("fileUrl", fileUrl);
  // };
  // const HandleDownloadDoc = async (file) => {};
  const HandleDownloadDoc = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;

    const fileName = fileUrl.split("/").pop();
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url: "store-language",
  //   method: "put",
  //   invalidateKey: "bankDetailsList",
  //   successCallback: () => {
  //     // reset();
  //     // addHandle();
  //   },
  // });
  const HandleReject = (id, type) => {
    setRowToEdit(id);
    // console.log("id", id);
    setAdd((val) => !val);
    setType(type);
  };
  const HandleView = (id) => {
    setRowToEdit(id);
    // console.log("id", id);
    setViewM((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/b2cAdmin/add-product", {
      state: { val },
    });
  };
  // console.log("rowToEdit", rowToEdit);
  console.log("rowData", rowData);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 290px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th style={{ minWidth: "40px" }}>#</th>
                <th style={{ minWidth: "120px" }}>Uploaded By</th>
                <th style={{ minWidth: "120px" }}>Uploaded At</th>
                <th style={{ minWidth: "120px" }}>Status</th>

                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.uploaded_by_name}</td>
                  <td>{row.updated_at}</td>
                  <td>{row.status}</td>
                  <td>
                    {" "}
                    {/* {perm?.includes("update") && ( */}
                    <>
                      {row.status === "PENDING" && (
                        <IoCheckmarkCircle
                          title="Approve"
                          size={15}
                          style={{ paddingRight: "7.5px", color: "green" }}
                          onClick={() => HandleApprove(row.id, "approve")}
                        />
                      )}
                    </>
                    {/* )}
                    {perm?.includes("update") && ( */}
                    <>
                      {row.status === "PENDING" && (
                        <IoIosCloseCircle
                          title="Reject"
                          size={15}
                          style={{ color: "red", paddingRight: "7.5px" }}
                          onClick={() => HandleReject(row.id, "reject")}
                        />
                      )}
                    </>
                    {/* )} */}
                    {row.status !== "PENDING" && (
                      <FaEye
                        title="View Remarks"
                        size={15}
                        style={{ color: "#495057", paddingRight: "7.5px" }}
                        onClick={() => HandleView(row.reason)}
                      />
                    )}
                    {/* {perm?.includes("export") && ( */}
                    <FaFileDownload
                      title="Download Excel"
                      size={15}
                      style={{ color: "#495057", paddingRight: "7.5px" }}
                      onClick={() => HandleDownloadDoc(row.file_path)}
                    />
                    {/* )} */}
                    {/* {row.status !== "APPROVED" && row.status !== "PENDING" && (
                    <FaEye
                      size={15}
                      style={{ color: "#495057" }}
                      onClick={() => HandleView(row.reason)}
                    />
                  )} */}
                    {/* <button
                    onClick={() => handleEditRow(row)}
                    className={styles.editbtn}
                  >
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button className={styles.dltbtn}>
                    <MdDelete
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal
            addHandle={addHandle}
            defaultValue={rowToEdit || ""}
            type={type || ""}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={viewM}
          onClick={addViewHandle}
        >
          <RemarkModal
            addHandle={addViewHandle}
            defaultValue={rowToEdit || ""}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default Table;
