import React, { useEffect, useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaSort } from "react-icons/fa";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import ViewCharges from "./ViewCharges";
import { GoDotFill } from "react-icons/go";
const Table = ({
  rowData,
  page,
  numberOfRecords,
  perm,
  type,
  arrayIds,
  setArrayIds,
}) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);

  const [selectAll, setSelectAll] = useState(false);
  const [char, setChar] = useState(false);
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };

  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const handleEditRow = (val) => {
    navigate("/add-product", {
      state: { val },
    });
  };
  useEffect(() => {
    setSelectAll(arrayIds.length === rowData.length && rowData.length > 0);
  }, [arrayIds, rowData]);

  const handleCheckboxChange = (id) => {
    setArrayIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setArrayIds([]);
    } else {
      setArrayIds(rowData.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 20%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th style={{ minWidth: "40px" }}>#</th>
                <th>Dealer</th>

                <th style={{ minWidth: "120px" }}>Product Type</th>
                <th style={{ minWidth: "120px" }}>Model Name</th>
                <th style={{ minWidth: "120px" }}>Model No.</th>
                <th style={{ minWidth: "120px" }}>Battery Type</th>
                <th style={{ minWidth: "120px" }}>Manufacturer Name</th>
                <th style={{ minWidth: "120px" }}>Ex-showroom Price</th>
                <th style={{ minWidth: "120px" }}>Loan to Value Ratio</th>
                <th style={{ minWidth: "120px" }}>Min Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Max Loan Amount</th>
                <th style={{ minWidth: "120px" }}>Min Loan Tenure</th>
                <th style={{ minWidth: "120px" }}>Max Loan Tenure</th>

                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      checked={arrayIds.includes(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </td>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.dealer_name}</td>
                  <td>{row.product_type}</td>
                  <td>
                    {row.status === "active" ? (
                      <span style={{ color: "green" }}>
                        <GoDotFill
                          size={15}
                          style={{
                            position: "relative",
                            top: "3px",
                            paddingRight: "3px",
                          }}
                        />
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {" "}
                        <GoDotFill
                          size={15}
                          style={{
                            position: "relative",
                            top: "3px",
                            paddingRight: "3px",
                          }}
                        />
                      </span>
                    )}
                    {row.name}{" "}
                  </td>
                  <td>{row.model_unq}</td>
                  <td>{row.battery_type}</td>
                  <td>{row.manufacture_name}</td>
                  <td>{row.ex_showroom_price}</td>
                  <td>{row.loan_to_value_ratio}</td>
                  <td>{row.min_loan_amount}</td>
                  <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td>
                  {/* <td>{row.status}</td> */}
                  {type !== "OEM" && (
                    <td>
                      {" "}
                      {perm?.includes("update") && (
                        <button
                          title="Edit"
                          type="button"
                          onClick={() => handleEditRow(row)}
                          className={styles.editbtn}
                        >
                          <AiFillEdit
                            size={12}
                            style={{ position: "relative", top: "1px" }}
                          />
                        </button>
                      )}
                      {perm?.includes("update") && (
                        <button
                          title="Product Charges"
                          type="button"
                          onClick={() => handleCharEditRow(row)}
                          className={styles.btnn_2}
                        >
                          <MdOutlineAddCircleOutline
                            size={12}
                            style={{
                              position: "relative",
                              top: "1px",
                            }}
                          />
                        </button>
                      )}
                      <button
                        type="button"
                        title="View Product Charges"
                        onClick={() => handleViewCharEditRow(row)}
                        className={styles.btnn_1}
                      >
                        <MdPlaylistAddCheckCircle
                          size={12}
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        />
                      </button>
                      {/* {perm?.includes("delete") && (
                        <button
                          title="Delete"
                          type="button"
                          className={styles.dltbtn}
                        >
                          <MdDelete
                            size={12}
                            style={{ position: "relative", top: "1px" }}
                          />
                        </button>
                      )} */}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default Table;
