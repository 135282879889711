import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaSort } from "react-icons/fa";
import ViewCharges from "./ViewCharges";
import {
  MdDelete,
  MdOutlineAddCircleOutline,
  MdPlaylistAddCheckCircle,
} from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import AddCharges from "./AddCharges";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [char, setChar] = useState(false);
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 20%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Manufacturer Name
                </th>

                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.manufacturer}</td>

                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit"
                        type="button"
                        onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {perm?.includes("update") && (
                      <button
                        title="Product Charges"
                        type="button"
                        onClick={() => handleCharEditRow(row)}
                        className={styles.btnn_1}
                      >
                        <MdOutlineAddCircleOutline
                          size={12}
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        />
                      </button>
                    )}
                    <button
                      type="button"
                      title="View Product Charges"
                      onClick={() => handleViewCharEditRow(row)}
                      className={styles.btnn_2}
                    >
                      <MdPlaylistAddCheckCircle
                        size={12}
                        style={{
                          position: "relative",
                          top: "1px",
                        }}
                      />
                    </button>
                    {/* {perm?.includes("delete") && (
                      <button
                        title="Delete"
                        type="button"
                        className={styles.dltbtn}
                      >
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default Table;
