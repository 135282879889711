import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import classes from "./Dashboard.module.css";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PointSty = () => {
  const chartData = {
    Mon: 1,
    Tue: 3,
    Wed: 2,
    Thur: 5,
    Fri: 1,
    Sat: 3,
    Sun: 6,
  };

  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: "Avg of Number of Power Cut Alert",
        data: Object.values(chartData),
        borderColor: "#36A2EB",
        borderWidth: 2,
        pointBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#E74C3C",
          "#2E86C1",
        ],
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
        pointRadius: 6,
        pointHoverRadius: 8,
        pointStyle: [
          "circle",
          "circle",
          "circle",
          "circle",
          "circle",
          "circle",
          "circle",
        ],
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Avg of Number of Power Cut Alert",
        font: {
          size: 16,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%" }} className={classes.shdbds}>
      <Line data={data} options={options} />
    </div>
  );
};

export default PointSty;
