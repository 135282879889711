import React, { useState, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
const LoanCom = () => {
  const [loanAmount, setLoanAmount] = useState(1000);
  const [interestRate, setInterestRate] = useState(1);
  const [loanDuration, setLoanDuration] = useState(1);
  const [monthlyInterest, setMonthlyInterest] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  useEffect(() => {
    const calculateLoanDetails = () => {
      if (loanAmount > 0 && interestRate > 0 && loanDuration > 0) {
        const interestPerYear = (loanAmount * interestRate) / 100;
        const monthlyInterestValue = interestPerYear / 12;
        const monthlyPaymentValue =
          monthlyInterestValue + loanAmount / loanDuration;
        const totalInterestValue = monthlyInterestValue * loanDuration;
        const totalRepaymentValue = monthlyPaymentValue * loanDuration;

        setMonthlyInterest(monthlyInterestValue.toFixed(2));
        setMonthlyPayment(monthlyPaymentValue.toFixed(2));
        setTotalInterest(totalInterestValue.toFixed(2));
        setTotalRepayment(totalRepaymentValue.toFixed(2));
      } else {
        setMonthlyInterest(0);
        setMonthlyPayment(0);
        setTotalInterest(0);
        setTotalRepayment(0);
      }
    };
    calculateLoanDetails();
  }, [loanAmount, interestRate, loanDuration]);
  const resetValues = () => {
    setLoanAmount(1000);
    setInterestRate(1);
    setLoanDuration(1);
  };

  return (
    <div className={styles.searchformasc}>
      <div className={styles.totalcheckconnnfth}>
        <div className={styles.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Loan Amount:
          </label>
          <input
            type="number"
            className={styles.inputstst}
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
          />
          <input
            style={{ marginTop: "5px" }}
            type="range"
            min="1000"
            max="1000000"
            step="1000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
          />
        </div>

        <div className={styles.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Interest Rate (%):
          </label>
          <input
            className={styles.inputstst}
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
          />
          <input
            style={{ marginTop: "5px" }}
            type="range"
            min="1"
            max="100"
            step="0.1"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
          />
        </div>

        <div className={styles.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "bold",
            }}
          >
            Loan Duration (Months):
          </label>
          <input
            className={styles.inputstst}
            type="number"
            value={loanDuration}
            onChange={(e) => setLoanDuration(Number(e.target.value))}
          />
          <input
            style={{ marginTop: "5px" }}
            type="range"
            min="1"
            max="360"
            step="1"
            value={loanDuration}
            onChange={(e) => setLoanDuration(Number(e.target.value))}
          />
        </div>
      </div>
      <button
        type="button"
        className={styles.Subbtn}
        onClick={resetValues}
        style={{ margin: "0 0 5px 0 " }}
      >
        Reset
      </button>
      <div className={styles.totalcheckconnn} style={{ marginTop: "15px" }}>
        <div className={styles.desdvvfremidata}>
          <p style={{ fontWeight: "bold" }}>
            Interest / Month: ₹ {monthlyInterest}
          </p>
        </div>
        <div className={styles.desdvvfremidata}>
          {" "}
          <p style={{ fontWeight: "bold" }}>EMI / Month: ₹ {monthlyPayment}</p>
        </div>
        <div className={styles.desdvvfremidata}>
          {" "}
          <p style={{ fontWeight: "bold" }}>Total Amount: ₹ {totalRepayment}</p>
        </div>
        <div className={styles.desdvvfremidata}>
          {" "}
          <p style={{ fontWeight: "bold" }}>
            Total Interest: ₹ {totalInterest}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoanCom;
