import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const navigate = useNavigate();
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const handleAccess = (val) => {
    navigate("/access-permission", {
      state: { val },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th>Role Name</th>
                <th> Access Permission</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>

                  <td>{row.user_type}</td>
                  <td>
                    {perm?.includes("update") && (
                      <button
                        type="button"
                        onClick={() => handleAccess(row.id)}
                        className={styles.accessbtn}
                      >
                        Access
                      </button>
                    )}
                  </td>
                  {/* <td>
                  <button className={styles.editbtn}  type="button">
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button className={styles.dltbtn}  type="button">
                    <MdDelete
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal
            addHandle={addHandle}
            defaultValue={rowToEdit !== null && rowData[rowToEdit]}
          />
        </Backdrop>
      </div>
    </div>
  );
};

export default Table;
