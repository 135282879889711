import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";

const GuaEign = ({
  showGen,
  setShowGen,
  showRegenButton,
  setShowRegenButton,
  productId,
  bbData,
}) => {
  const { borrId, handleNext, setActiveStep, userId, userD } =
    useContext(LoanContext);
  const { ver } = useContext(AuthContext);
  const { showToast } = useToast();

  const [genData, setGenData] = useState(null);
  useEffect(() => {
    if (
      bbData?.skip_guarantor ||
      (productId === "1" && bbData?.guarantor_step === "not_required")
    ) {
      setActiveStep(14);
    }
  }, [productId, bbData, setActiveStep]);
  const [{ data: ForGenDataGG }] = useQueries({
    queries: [
      {
        queryKey: ["generate"],
        queryFn: async () => {
          const payload = {
            borrower_id: borrId,
            user_type: "GUARANTOR",
            user_id: userD?.id || userId,
          };
          const res = await api.post(
            "admin/get-user-details-for-esign",
            payload
          );
          return res.data;
        },

        initialData: [],
      },
    ],
  });
  const HandleGenrate = async () => {
    try {
      const payload = {
        borrower_id: borrId,
        applicant_type: "GUARANTOR",
        user_id: userD?.id || userId,
        email: ForGenDataGG?.data?.email,
        city: ForGenDataGG?.data?.city,
        mobile: ForGenDataGG?.data?.mobile,
        name: ForGenDataGG?.data?.name,
      };

      const response = await api.post(`${ver}admin-generate-esign`, payload);
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        //   console.log("API call successful:", response.data);
        setShowGen(false);
        showToast(message, status);
        setGenData(response.data);
        setTimeout(() => {
          setShowRegenButton(true);
        }, 120000);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
    }
  };
  const handleCheckStatus = async () => {
    try {
      const payload = {
        borrower_id: borrId,
        applicant_type: "GUARANTOR",
        user_id: userD?.id || userId,
        doc_id: genData?.data?.doc_id || "",
      };

      const response = await api.post(
        `${ver}admin-download-esigned-doc`,
        payload
      );
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        //   console.log("API call successful:", response.data);
        showToast(message, status);
        handleNext();
      } else {
        //    console.log("res", response.data);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
    }
  };
  const handleReGenerate = async () => {
    try {
      const payload = {
        borrower_id: borrId,
        applicant_type: "GUARANTOR",
        user_id: userD?.id || userId,
        doc_id: genData?.data?.doc_id || "",
      };

      const response = await api.post(
        `${ver}admin-download-esigned-doc`,
        payload
      );
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        // console.log("API call successful:", response.data);
        showToast(message, status);
        setShowRegenButton(false);
        setTimeout(() => {
          setShowRegenButton(true);
        }, 120000);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
    }
  };

  return (
    <div>
      <p className={classes.applheader}>LOAN AGREEMENT GENERATION</p>
      <div className={classes.totalcheckconnfdfvmn}>
        {/* start */}
        <div className={classes.applylonffer}>
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            Guarantor Details
          </p>
          <div className={classes.divfrtheredr}>
            <div className={classes.notAppre}>
              {/* <RxCheckCircled size="20" /> */}
              <RxCrossCircled size="25" />
            </div>
            <div className={classes.applylonfthn}>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Name</p>
                <p className={classes.txtfooflbtoneffs}>
                  {ForGenDataGG?.data?.name}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Mobile</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {ForGenDataGG?.data?.mobile}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>Email</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {ForGenDataGG?.data?.email}
                </p>
              </div>
              <div className={classes.divinffdffg}>
                <p className={classes.txtfoofl}>City</p>
                <p className={classes.txtfooflbtoneffs}>
                  {" "}
                  {ForGenDataGG?.data?.city}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* emd */}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {showGen && (
          <button
            type="button"
            className={classes.Subbtn}
            style={{
              width: "90px",
            }}
            onClick={HandleGenrate}
          >
            Generate
          </button>
        )}
        {!showGen && (
          <button
            type="button"
            className={classes.Subbtn}
            style={{
              width: "120px",
              background: "#45cb85",
              marginRight: "15px",
            }}
            onClick={handleCheckStatus}
          >
            Check Status
          </button>
        )}
        {showRegenButton && (
          <button
            type="button"
            className={classes.Subbtn}
            style={{
              width: "120px",
            }}
            onClick={handleReGenerate}
          >
            Resend URL
          </button>
        )}
      </div>
    </div>
  );
};

export default GuaEign;
