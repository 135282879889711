import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import classes from "./Dashboard.module.css";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AvgIgniT = () => {
  const chartData = {
    Mon: 8.1,
    Tue: 9.0,
    Wed: 10,
    Thur: 8.35,
    Fri: 7.35,
    Sat: 8.35,
    Sun: 10,
  };

  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: "Ignition Time",
        data: Object.values(chartData),
        backgroundColor: [
          "#36A2EB", // Blue
          "#FF6384", // Red
          "#FFCE56", // Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Purple
          "#FF9F40", // Orange
          "#C9CBCF", // Gray
        ],
        hoverBackgroundColor: [
          "#2E86C1", // Darker Blue
          "#E74C3C", // Darker Red
          "#F4D03F", // Darker Yellow
          "#17A589", // Darker Teal
          "#8E44AD", // Darker Purple
          "#E67E22", // Darker Orange
          "#95A5A6", // Darker Gray
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Ignition Start at(24:00)", // Chart title inside the graph
        font: {
          size: 14, // Title font size
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%" }} className={classes.shdbds}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default AvgIgniT;
