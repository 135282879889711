import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { useQueries, useQuery } from "@tanstack/react-query";

const ProD = ({ addHandle, defaultValue }) => {
  const [existingData, setExistingData] = useState(null);
  console.log("defaultValue", defaultValue);
  const fetchData = async () => {
    const response = await api.get(
      `admin/get-product-scheme/${defaultValue.borrower_id}`
    );
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["prodd", defaultValue],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  return (
    <div
      className={styles.modalcontainer}
      style={{ width: "750px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Product Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Product Name
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.name || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Battery Type
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.battery_type || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Ex Showroom Price
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.ex_showroom_price || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Loan To Value Ratio
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.loan_to_value_ratio || "-"}
            </label>
          </div>
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Manufacture Name
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.manufacture_name || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Min Loan Amount
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.min_loan_amount || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Max Loan Amount
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.max_loan_amount || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Min Loan Tenure
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.min_loan_tenure || "-"}
            </label>
          </div>
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Max Loan Tenure
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.max_loan_tenure || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Product Type
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.product_type || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Interest
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.interest || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Processing Fee
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.processing_fee || "-"}
            </label>
          </div>
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Subvention Amount
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.subvention_amount || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              FLDG
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.fldg || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              IOT
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.iot || "-"}
            </label>
          </div>
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Insurance 1st Year
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.insurance_1st_year || "-"}
            </label>
          </div>
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          className={styles.modalform}
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "0.7rem",
            flexDirection: "row",
          }}
        >
          <div
            style={{ width: "200px", display: "flex", flexDirection: "column" }}
          >
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Insurance 2nd Year
            </label>
            <label
              style={{
                color: "#494646",
                fontSize: "13px",
                fontWeight: "normal",
              }}
            >
              {existingData?.insurance_2nd_year || "-"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProD;
