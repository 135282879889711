import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import classes from "./Dashboard.module.css";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HourBatteryC = () => {
  const chartData = {
    "08:00 - 09:00": 12,
    "09:00 - 10:00": 15,
    "10:00 - 11:00": 13,
    "11:00 - 12:00": 16,
    "12:00 - 13:00": 14,
    "13:00 - 14:00": 12,
    "14:00 - 15:00": 15,
    "15:00 - 16:00": 17,
    "16:00 - 17:00": 14,
    "17:00 - 18:00": 13,
    "18:00 - 19:00": 16,
    "19:00 - 20:00": 15,
    "20:00 - 21:00": 14,
    "21:00 - 22:00": 13,
    "22:00 - 23:00": 12,
    "23:00 - 24:00": 10,
  };

  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: "Average Battery Consumption (%)",
        data: Object.values(chartData),
        backgroundColor: "#FFCE56",
        hoverBackgroundColor: "#F4D03F",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Average Battery Consumption(%)",
        font: {
          size: 14,
          weight: "bold",
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%" }} className={classes.shdbds}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default HourBatteryC;
