import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
const SearchComp = ({ onSearch, filterD }) => {
  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    if (data.pan && data.pan.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar && data.aadhar.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }

    onSearch(data);
  };
  useEffect(() => {
    if (filterD) {
      setValue("start_date", filterD.start_date || "");
      setValue("end_date", filterD.end_date || "");
      setValue("dealer_name", filterD.dealer_name || "");
      setValue("dealer_id", filterD.dealer_id || "");
      setValue("mobile", filterD.mobile || "");
      setValue("email", filterD.email || "");
      setValue("pan", filterD.pan || "");
      setValue("aadhar", filterD.aadhar || "");
      setValue("dealer_type", filterD.dealer_type || "");
    }
  }, [filterD, setValue]);
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("dealer_name")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer ID
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("dealer_id")}
            />
          </div>
        </div>
        <div
          className={styles.fourinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          {/* // dealer_name, dealer_id, dealer_type; */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile No
            </label>
            <input
              type="mobile"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <input
              type="email"
              className={styles.inpttsc}
              {...register("email")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar No.
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("aadhar")}
            />
          </div>
        </div>
        <div
          className={styles.oneinputads}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer Type
            </label>
            {/* <input
              type="number"
              className={styles.inpttsc}
              {...register("aadhar")}
            /> */}
            <select
              className={styles.inpttsc}
              {...register("dealer_type")}
              style={{ height: "29px" }}
            >
              <option value="">Select Dealer Type</option>
              <option value="B2B">B2B</option>
              <option value="B2C">B2C</option>
            </select>
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
