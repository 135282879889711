import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";

import { Controller, useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";

const Settings = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm();

  const [{ data: lenderList }, { data: settingStatus }] = useQueries({
    queries: [
      {
        queryKey: ["userType"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["setSt"],
        queryFn: () =>
          api
            .get(`admin/get-lender-loan-setting/${defaultValue?.id}`)
            .then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (settingStatus?.data) {
      setValue("co_applicant_step", settingStatus.data.co_applicant_step || "");
      setValue("guarantor_step", settingStatus.data.guarantor_step || "");
    } else {
      setValue("co_applicant_step", "");
      setValue("guarantor_step", "");
    }
  }, [settingStatus, setValue, defaultValue]);

  console.log("settingStatus", settingStatus);
  const onSubmit = async (data) => {
    let payload;
    payload = {
      ...(!settingStatus?.data && {
        lender_id: defaultValue?.id,
      }),
      co_applicant_step: data.co_applicant_step,
      guarantor_step: data.guarantor_step,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: settingStatus?.data
      ? `admin/update-lender-loan-setting/${defaultValue?.id}`
      : "admin/store-lender-loan-setting",

    method: "post",
    invalidateKey: "borrowerList",
    successCallback: () => {
      reset();
      addHandle();
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {settingStatus?.data ? "Change Loan Setting" : "Store Loan Setting"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        {/* <div className={styles.modalform}>
          <label
            style={{
              color: "#494646",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
          Lender
          </label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <ListWithSearch
                options={lenderList?.data || []}
                getOptionLabel={(val) => val.name || ""}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>{`${option.name}`}</li>
                )}
                className={styles.listSrch}
                onChange={(event, selectedValue) => {
                  onChange(selectedValue ? selectedValue?.id : null);
                }}
                value={
                  lenderList?.data?.find((employee) => employee.id === value) ||
                  null
                }
              />
            )}
            name={`lender_id`}
          />
          {errors.lender_id && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div> */}
        <div className={styles.modalform} style={{ marginTop: "10px" }}>
          <label
            style={{
              color: "#494646",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            Co-applicant Step
          </label>

          <select
            {...register("co_applicant_step", { required: true })}
            className={styles.mdlinput}
            style={{ height: "29px", color: "#000" }}
          >
            <option value="">Co-applicant Step</option>
            <option value="required">Required</option>
            <option value="optional">Optional</option>
            <option value="not_required">Not Required</option>
          </select>
          {errors.co_applicant_step && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={styles.modalform} style={{ marginTop: "10px" }}>
          <label
            style={{
              color: "#494646",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            Guarantor Step
          </label>

          <select
            {...register("guarantor_step", { required: true })}
            className={styles.mdlinput}
            style={{ height: "29px", color: "#000" }}
          >
            <option value="">Co-applicant Step</option>
            <option value="required">Required</option>
            <option value="optional">Optional</option>
            <option value="not_required">Not Required</option>
          </select>
          {errors.guarantor_step && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Settings;
