import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import GuaDoc from "./GuaDoc";
import CoApDoc from "./CoApDoc";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
const StepNine = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { borrId, handleNext, setActiveStep, activeStep } =
    useContext(LoanContext);

  const [bbData, setbbData] = useState(null);
  const [type, setType] = useState(null);
  const [productId, setProductId] = useState(null);
  const [intitialStep, setInitialStep] = useState(false);
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //   console.log("Loan Status:", response.data);
          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProductId(product_id);
          if (step === 12) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);

  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-borrower-details", {
            borrower_id: borrId,
          });

          const borrowerData = response?.data?.data;
          setbbData(borrowerData);
          //  console.log(borrowerData);

          if (borrowerData?.skip_co_applicant && borrowerData?.skip_guarantor) {
            setActiveStep(9);
          }
          if (
            productId === "1" &&
            borrowerData?.co_applicant_step === "not_required" &&
            borrowerData?.guarantor_step === "not_required"
          ) {
            setActiveStep(9);
          }
          if (
            borrowerData?.skip_co_applicant ||
            (productId === "1" &&
              borrowerData?.co_applicant_step === "not_required")
          ) {
            setInitialStep(true);
          }
        } catch (error) {
          //  console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId, productId]);
  return (
    <div className={classes.tablco}>
      {!intitialStep && <CoApDoc setInitialStep={setInitialStep} />}
      {intitialStep && <GuaDoc productId={productId} bbData={bbData} />}
    </div>
  );
};

export default StepNine;
