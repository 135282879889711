import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const SearchOEM = ({ onSearch, filterD }) => {
  const { register, handleSubmit, reset, watch, setValue, control } = useForm();
  useEffect(() => {
    if (filterD) {
      setValue("start_date", filterD.start_date || "");
      setValue("end_date", filterD.end_date || "");
      setValue("max_loan_amount", filterD.max_loan_amount || "");
      setValue("min_loan_tenure", filterD.min_loan_tenure || "");
      setValue("max_loan_tenure", filterD.max_loan_tenure || "");
      setValue("product_type", filterD.product_type || "");
      setValue("dealer_id", filterD.dealer_id || "");
      setValue("model_unq", filterD.model_unq || "");
      setValue("name", filterD.name || "");
      setValue("battery_type", filterD.battery_type || "");
      setValue("ex_showroom_price", filterD.ex_showroom_price || "");
      setValue("loan_to_value_ratio", filterD.loan_to_value_ratio || "");

      setValue("interest", filterD.interest || "");
    }
  }, [filterD, setValue]);
  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
   
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const [{ data: OemList }, { data: lenderList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-oem-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              OEM
            </label>
            {/* <input
              type="text"
              className={styles.inpttsc}
              {...register("oem_id")}
            /> */}
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearc
                  options={OemList?.data || []}
                  getOptionLabel={(val) => val.manufacturer || ""}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                    >{`${option.manufacturer}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    OemList?.data?.find((employee) => employee.id === value) ||
                    null
                  }
                />
              )}
              name={`oem_id`}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Product Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("product_name")}
            />
          </div>
        </div>

        <div
          className={styles.threeinputads}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Product Type
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("product_type")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              OEM Product Id
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("oem_product_unq")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Battery Type
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("battery_type")}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchOEM;
