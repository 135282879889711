import React, { useContext } from "react";
import styles from "./SearchDasboard.module.css";
import { AiFillProduct } from "react-icons/ai";
import { AuthContext } from "../../../context/AuthContext";
const SearchDashboard = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className={styles.searchBody}>
      <div className={styles.leftsidesearchcon}>
        <h5 className={styles.gmtext}>
          Good Morning, {user?.name || "Admin"}!
        </h5>
        <p className={styles.secText}>
          Here's what's happening with your store today.
        </p>
      </div>
      <div className={styles.selectcontefrosearch}>
        <div className={styles.searchinfordash}>
          <select className={styles.searchselc}>
            <option>--Select Product--</option>
          </select>
          <div className={styles.probx}>
            <AiFillProduct />
          </div>
        </div>
        <div className={styles.searchinfordash}>
          <select style={{ width: "145px" }} className={styles.searchselc}>
            <option>--Select Finacial Year--</option>
          </select>
          <div className={styles.probx}>
            <AiFillProduct />
          </div>
        </div>
        <div className={styles.searchinfordash}>
          <select className={styles.searchselc}>
            <option>--Select Dealers--</option>
          </select>
          <div className={styles.probx}>
            <AiFillProduct />
          </div>
        </div>
        <button className={styles.btnforsearch}>Search</button>
      </div>
    </div>
  );
};

export default SearchDashboard;
