import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { Controller, useForm } from "react-hook-form";
import AddModal from "./AddModal";
import { Backdrop } from "@mui/material";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import AdditionalSix from "./AdditionalSix";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import AdharVer from "./AdharVer";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
const StepSix = ({ setActiveStep }) => {
  const {
    userD,
    handleNext,
    borrId,
    dataFromAdharG,
    setDataFromAdharG,
    borrwD,
    setBorrowD,
    userId,
  } = useContext(LoanContext);
  const [add, setAdd] = useState(false);
  const [intitialStep, setInitialStep] = useState(false);
  const [credit, setCredit] = useState(false);
  const [isPanVerifying, setIsPanVerifying] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [adharStatus, setAdharStatus] = useState(false);
  const [panStatus, setPanStatus] = useState(false);
  const [aadharResponse, setAadharResponse] = useState(null);
  const [panResponse, setPanResponse] = useState(null);
  const [productId, setProductId] = useState(null);
  const [bgen, setBgen] = useState(null);

  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const { token, baseURL, baseDoc, ver } = useContext(AuthContext);
  const aadharNum = watch("aadhar_number");
  const panNum = watch("pan_number");
  const mblNum = watch("mobile_number");
  const selectedGender = watch("gender");
  const checkAadharVerification = async () => {
    if (aadharNum?.length !== 12) {
      showToast("Aadhar number must be exactly 12 digits", "error"); // Show toast for invalid length
      return;
    }

    try {
      setIsVerifying(true);
      //  const payload = {
      //    user_id: userD?.id || userId,
      //    borrower_id: borrId,
      //    applicant_type: "GUARANTOR",
      //    aadhar: aadharNum,
      //  };

      const checkResponse = await api.get(
        `admin/check-application-with-aadhar/${aadharNum}`
      );

      if (checkResponse.data.status === "success") {
        // If the check is successful, proceed with the verification
        await handleAadharVerification();
      }
    } catch (error) {
      const { message, errors } = error.response?.data || {};
      showToast(message || "Aadhar verification check failed", "error");
    } finally {
      setIsVerifying(false); // Set loading state back to false
    }
  };
  const handleAadharVerification = async () => {
    try {
      setIsVerifying(true);
      const payload = {
        user_id: userD?.id || userId,
        borrower_id: borrId,
        applicant_type: "GUARANTOR",
        aadhar: aadharNum,
      };

      const response = await api.post(`${ver}admin-verify-aadhar`, payload);
      setAadharResponse(response.data.data);

      addHandle();
      //  console.log("Aadhar verification successful:", response.data);
    } catch (error) {
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    } finally {
      setIsVerifying(false); // Set loading state back to false
    }
  };
  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-borrower-details", {
            borrower_id: borrId,
          });

          //  console.log(response.data.data);
          setBgen(response.data.data);
        } catch (error) {
          //   console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId]);
  useEffect(() => {
    if (adharStatus) {
      setAdharStatus(false);
      setPanStatus(false);
    }
  }, [aadharNum]);
  useEffect(() => {
    if (mblNum) {
      setCredit(false);
    }
  }, [mblNum]);
  useEffect(() => {
    if (panStatus) {
      setPanStatus(false);
    }
  }, [panNum]);
  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          // const response = await api.post(
          //   `admin/get-borrower-loan-details/${borrId}`
          // );
          const response = await api.post("admin/get-borrower-details", {
            borrower_id: borrId,
          });
          //  console.log(response.data.data);
          setBgen(response.data.data);
        } catch (error) {
          //  console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId]);

  const handlePanVerification = async () => {
    if (panNum?.length !== 10) {
      showToast("PAN number must be exactly 12 digits", "error");
      return;
    }

    try {
      setIsPanVerifying(true);
      const payload = {
        user_id: userD?.id || userId,
        borrower_id: borrId,
        applicant_type: "GUARANTOR",
        pan: panNum,
        name: dataFromAdharG?.name,
      };

      const response = await api.post(
        `${ver}admin-verify-pan-details`,
        payload
      );
      setPanResponse(response.data.data);
      showToast("PAN Verification successful", "success");
      setPanStatus(true);
      //console.log("Pan verification successful:", response.data);
    } catch (error) {
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    } finally {
      setIsPanVerifying(false); // Set loading state back to false
    }
  };
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          // console.log("Loan Status:", response.data);

          const { product_id, step_details, skip_coapplicant_guarantor } =
            response?.data;
          const { step } = step_details;

          setProductId(product_id);
          if (
            skip_coapplicant_guarantor ||
            (product_id === "1" && bgen?.guarantor_step === "not_required")
          ) {
            handleSkip();
          }

          if (step === 7) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId, bgen?.guarantor_step]);
  const [{ data: genderList }, { data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["gender"],
        queryFn: () => api.get("get-genders").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["rela"],
        queryFn: async () => {
          // const res = await api.get(`get-relations/${bgen?.gender}`);
          // return res.data;
          const endpoint =
            bgen?.gender === "Female" && productId === "4"
              ? `get-relations/${bgen.gender}`
              : "get-relations";

          const res = await api.get(endpoint);
          return res.data;
        },
        //  enabled: !!selectedGender,
        enabled: !!bgen?.gender && !!productId,
        initialData: [],
      },
    ],
  });
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleSkip = async () => {
    const payload = {
      borrower_id: borrId,
      skip_guarantor: "YES",
    };

    try {
      const response = await api.post("admin/skip-guarantor", payload);
      // Assuming the response has a success status or message
      if (response.data.status === "success") {
        showToast("Successfully skipped Guarantor", "success");
        handleNext();
      } else {
        showToast("Failed to skip Guarantor", "error");
      }
    } catch (error) {
      // console.error("Error skipping Guarantor:", error);
      showToast("An error occurred while skipping Guarantor", "error");
    }
  };
  const handleDownloadForm = async () => {
    const payload = {
      borrower_id: borrId,
      applicant_type: "GUARANTOR",
    };

    try {
      const queryString = new URLSearchParams(payload).toString();
      const response = await api.get(
        `admin/get-name-declaration?${queryString}`
      );
      if (response.data.status === "success" && response.data.file_url) {
        const downloadUrl = `${baseDoc}${response.data.file_url}`;
        //  console.log("downloadUrl", downloadUrl);

        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "name_declaration_form.pdf");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        showToast("Failed to retrieve the file URL", "error");
      }
    } catch (error) {
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    }
  };
  useEffect(() => {
    if (
      bgen?.gender === "Female" &&
      dataFromAdharG?.gender === "F" &&
      productId === "4"
    ) {
      showToast(
        "If Borrower is Female Then Guarantor cannot be Female For Battery Product.",
        "error"
      );
      setAdharStatus(false);
      setPanStatus(false);
    }
  }, [bgen, dataFromAdharG, productId]);
  const onSubmit = async (data) => {
    if (
      bgen.gender === "Female" &&
      data.gender === "Female" &&
      productId === "4"
    ) {
      showToast(
        "If Borrower is Female Then Guarantor cannot be Female For Battery Product.",
        "error"
      );
      setAdharStatus(false);
      setPanStatus(false);
      return;
    }
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("aadhar_number", data.aadhar_number);
    formData.append("pan_number", data.pan_number);
    formData.append("full_name", data.full_name);
    formData.append("dob", data.dob);
    formData.append("gender", data.gender);
    formData.append("mobile_number", data?.mobile_number || "");
    formData.append(
      "alternative_mobile_number",
      data?.alternative_mobile_number || ""
    );
    formData.append("email", data?.email || "");
    formData.append("relation_with_borrower", data.relation_with_borrower);
    formData.append("father_name", data.father_name);
    formData.append("is_aadhar_verified", adharStatus ? "Yes" : "No");
    formData.append("is_pan_verified", panStatus ? "Yes" : "No");
    const fileFields = ["name_declaration"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = `${baseURL}admin/store-guarantor-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      //   console.log("Form submitted successfully:", response.data);
      setInitialStep(true);
    } catch (error) {
      const { message, errors } = error.response.data;

      showToast(message, "error");
    }
  };
  useEffect(() => {
    if (dataFromAdharG) {
      setValue("full_name", dataFromAdharG.name);
      setValue("mobile", dataFromAdharG.gender);
      setValue("father_name", dataFromAdharG.father_name);
      const [day, month, year] = dataFromAdharG?.dob?.split("-");
      const formattedDOB = `${year}-${month}-${day}`;
      setValue("dob", formattedDOB);
      const gender =
        dataFromAdharG.gender === "M"
          ? "Male"
          : dataFromAdharG.gender === "F"
          ? "Female"
          : dataFromAdharG.gender;
      setValue("gender", gender);
    }
  }, [dataFromAdharG, setValue]);
  const handleCredit = async () => {
    const gender =
      dataFromAdharG.gender === "M"
        ? "Male"
        : dataFromAdharG.gender === "F"
        ? "Female"
        : dataFromAdharG.gender;
    const [day, month, year] = dataFromAdharG?.dob?.split("-");
    const formattedDOB = `${year}-${month}-${day}`;
    const payload = {
      borrower_id: borrId,
      applicant_type: "GUARANTOR",
      user_id: userD?.id || userId,
      mobile: mblNum,
      pan: panNum,
      name: panResponse?.name,
      pin: dataFromAdharG?.full_address?.pc,
      dob: formattedDOB,
    };

    try {
      const response = await api.post(
        `${ver}admin-get-credit-score-details`,
        // `${ver}get-credit-score-details`,
        // "v2/get-credit-score-details",
        payload
      );
      if (response?.data?.status === "success") {
        setCredit(true);
      } else {
        setCredit(false);
      }
    } catch (error) {
      const { message, errors } = error.response.data;

      showToast(message, "error");
    }
  };
  return (
    <div>
      {!intitialStep && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
          {/* start from here  */}
          <div
            style={{
              marginTop: "10px",
              borderTop: "1px solid #ddd",
              padding: "15px 0",
            }}
          >
            <div className={classes.flfosmc}>
              <p className={classes.applheader}>GUARANTOR DETAILS</p>
              {(productId === "4" ||
                productId !== "1" ||
                bgen?.guarantor_step !== "required") &&
                bgen?.gender !== "Female" && (
                  <button
                    className={classes.Subbtn}
                    type="button"
                    style={{ width: "150px" }}
                    onClick={handleSkip}
                  >
                    Skip Guarantor
                  </button>
                )}
              {/* {productId === "4" &&
                bgen?.gender !== "Female" &&
                bgen?.guarantor_step !== "required" && (
                  <button
                    className={classes.Subbtn}
                    type="button"
                    style={{ width: "150px" }}
                    onClick={handleSkip}
                  >
                    Skip Guarantor
                  </button>
                )} */}
            </div>
            <div className={classes.totalcheckconnn}>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Aadhar Number
                  <span className={classes.requ}>*</span>
                </label>
                <div style={{ display: "flex", width: "100%" }}>
                  <input
                    // placeholder="Enter Name"
                    {...register("aadhar_number", { required: true })}
                    type="text"
                    style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                    className={classes.inputstst}
                  />
                  <button
                    type="button"
                    style={{
                      background: adharStatus ? "#45cb85" : "#fa6548",
                      border: "none",
                      color: "#fff",
                      borderRadius: "  0 5px 5px 0  ",
                      fontSize: "11px",
                    }}
                    onClick={checkAadharVerification}
                  >
                    {adharStatus ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  PAN Number
                  <span className={classes.requ}>*</span>
                </label>
                <div style={{ display: "flex", width: "100%" }}>
                  <input
                    // placeholder="Enter Name"
                    {...register("pan_number", { required: true })}
                    type="text"
                    style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                    className={classes.inputstst}
                  />
                  <button
                    type="button"
                    disabled={isPanVerifying}
                    onClick={handlePanVerification}
                    style={{
                      background: panStatus ? "#45cb85" : "#fa6548",
                      border: "none",
                      color: "#fff",
                      borderRadius: "  0 5px 5px 0  ",
                      fontSize: "11px",
                    }}
                  >
                    {panStatus ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
              {adharStatus && panStatus && (
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    Mobile Number<span className={classes.requ}>*</span>
                  </label>
                  <input
                    // placeholder="Enter Name"
                    {...register("mobile_number", { required: true })}
                    type="number"
                    className={classes.inputstst}
                  />

                  {errors.mobile_number && (
                    <span className={classes.error}>
                      This Field is Missing.
                    </span>
                  )}
                </div>
              )}
              {adharStatus && panStatus && !credit && (
                <button
                  className={classes.Subbtn}
                  style={{
                    height: "29px",
                    marginTop: "15px",
                    maxWidth: "150px",
                  }}
                  type="button"
                  onClick={handleCredit}
                >
                  Check Credit Score
                </button>
              )}
            </div>
          </div>
          {adharStatus && panStatus && credit && (
            <div className={classes.totalcheckconnn}>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Full Name<span className={classes.requ}>*</span>
                </label>
                <input
                  disabled
                  {...register("full_name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.full_name && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  DOB<span className={classes.requ}>*</span>
                </label>
                <input
                  disabled
                  {...register("dob", { required: true })}
                  type="date"
                  className={classes.inputstst}
                />

                {errors.dob && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Email Id<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  className={classes.inputstst}
                  onChange={(e) => {
                    const lowercaseValue = e.target.value.toLowerCase();
                    e.target.value = lowercaseValue;
                  }}
                />

                {errors.email && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>{" "}
              {/* <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Mobile Number<span className={classes.requ}>*</span>
                </label>
                <input
                  // placeholder="Enter Name"
                  {...register("mobile_number", { required: true })}
                  type="number"
                  className={classes.inputstst}
                />

                {errors.mobile_number && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div> */}
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Alternative Mobile Number
                </label>
                <input
                  {...register("alternative_mobile_number")}
                  type="number"
                  className={classes.inputstst}
                />
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Gender<span className={classes.requ}>*</span>
                </label>

                <select
                  disabled
                  {...register("gender", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option value="">--Select Gender--</option>
                  {genderList?.data?.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </select>
                {errors.gender && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Father's Name<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("father_name", {
                    required: true,
                    validate: (value) =>
                      /^[A-Za-z\s]+$/.test(value) ||
                      "Only letters and spaces are allowed.",
                  })}
                  type="text"
                  disabled={Boolean(dataFromAdharG?.father_name)}
                  className={classes.inputstst}
                />

                {errors.father_name && (
                  <span className={classes.error}>
                    {errors.father_name.message || "This Field is Missing."}
                  </span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Relation with Borrower<span className={classes.requ}>*</span>
                </label>

                <select
                  {...register("relation_with_borrower", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option value="">--Select Relation with Borrower--</option>
                  {relationList?.data?.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </select>
                {errors.relation_with_borrower && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              {panResponse?.name_match_score < 70 && (
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    Name Decleration<span className={classes.requ}>*</span>
                  </label>
                  <div style={{ display: "flex", width: "100%" }}>
                    <input
                      {...register("name_declaration", { required: true })}
                      type="file"
                      className={classes.uploadbxscff}
                    />
                  </div>
                  {errors.name_declaration && (
                    <span className={classes.error}>
                      This Field is Missing.
                    </span>
                  )}
                </div>
              )}
              {panResponse?.name_match_score < 70 && (
                <button
                  className={classes.Subbtn}
                  style={{ height: "29px", marginTop: "15px" }}
                  type="button"
                  onClick={handleDownloadForm}
                >
                  Download Name Decleration Form
                </button>
              )}
            </div>
          )}
          {adharStatus && panStatus && credit && (
            <div
              className={classes.buttonasc}
              style={{ marginTop: "10px", textAlign: "left" }}
            >
              <button
                type="submit"
                className={classes.Subbtn}
                style={{ width: "90px" }}
              >
                Submit
              </button>
            </div>
          )}
          {/* end Here  */}
        </form>
      )}
      {intitialStep && <AdditionalSix productId={productId} />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AdharVer
          addHandle={addHandle}
          setAdharStatus={setAdharStatus}
          borrId={borrId}
          userD={userD}
          aadharResponse={aadharResponse}
          aadharNum={aadharNum}
          setDataFromAdhar={setDataFromAdharG}
          type="GUARANTOR"
        />
      </Backdrop>
    </div>
  );
};

export default StepSix;
