import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import classes from "./Dashboard.module.css";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const AvgIdiling = () => {
  const chartData = {
    Mon: 1.3,
    Tue: 0.5,
    Wed: 2,
    Thur: 3,
    Fri: 3.5,
    Sat: 1,
    Sun: 2,
  };

  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        data: Object.values(chartData),
        backgroundColor: [
          "#36A2EB", // Blue
          "#FF6384", // Red
          "#FFCE56", // Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Purple
          "#FF9F40", // Orange
          "#C9CBCF", // Gray
        ],
        hoverBackgroundColor: [
          "#2E86C1", // Darker Blue
          "#E74C3C", // Darker Red
          "#F4D03F", // Darker Yellow
          "#17A589", // Darker Teal
          "#8E44AD", // Darker Purple
          "#E67E22", // Darker Orange
          "#95A5A6", // Darker Gray
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 8, // Reduced legend label size
          },
        },
      },
      title: {
        display: true,
        text: "Avg Idling Time(Hours)",
        font: {
          size: 14, // Reduced chart title size
          weight: "bold",
        },
        color: "#333",
      },
      tooltip: {
        titleFont: {
          size: 10, // Reduced tooltip title font size
        },
        bodyFont: {
          size: 15, // Reduced tooltip body font size
        },
      },
    },
  };

  return (
    <div className={classes.shdbds} style={{ width: "100%" }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default AvgIdiling;
