import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
const ViewCharges = ({ addHandle, defaultValue }) => {
  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-active-charges-by-lender/${defaultValue.id}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["editData", defaultValue],
    queryFn: fetchPermissions,
  });
  const { data: permissionsData } = PermissionsResponse;
  return (
    <div
      className={styles.modalcontainer}
      style={{ width: "540px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Product Charges Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{
          borderBotom: "1px solid #red",
          height: "300px",
          overflowY: "scroll",
        }}
      >
        <table>
          <thead>
            <tr>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Lender Name
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Charges Head
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Total Charge Value
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Finayo Income
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                FLDG Income
              </th>
            </tr>
          </thead>
          <tbody>
            {permissionsData?.data?.map((row, index) => (
              <tr key={index}>
                <td>{row.lender?.name || "-"}</td>
                <td>{row.charge_head}</td>
                <td>{row.charge_value}</td>
                <td>{row.finayo_share}</td>
                <td>{row.nbfc_share}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            {/* <button type="submit" className={styles.Subbtn}>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCharges;
