import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { BiCylinder } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
const OEMBottom = () => {
  const fetchData = async (searchData) => {
    const payload = {};

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("admin/get-oem-yearly-counts");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["yearCount"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {},
  });
  const { data: yearCount } = DetailsResponse;
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Loan Pipeline
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              In Progress
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.loan_pipeline}
              </p>
              <div
                style={{
                  background: "#ffbe0b",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Pending Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              In Approved
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                N/A
              </p>
              <div
                style={{
                  background: "#45cb85",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Approved Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              Disbursed
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.approved_application}
              </p>
              <div
                style={{
                  background: "#f06548",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Rejected Applications
            </p>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                paddingTop: "1px",
              }}
            >
              Rejected
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.rejected_application}
              </p>
              <div
                style={{
                  background: "#25a546",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocontforbt}
              >
                <BiCylinder size={22} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OEMBottom;
